<template>
  <div class="tablebody">
    <div class="info">
      <b-btn class='paycount' size="sm" v-on:click="updatedata"> <b-icon-arrow-repeat> </b-icon-arrow-repeat> </b-btn>
    </div>
    <b-container fluid class="justify-content-md-center">
      <b-row>
        <b-col md="3">
          <b-input-group class="my-1">
            <b-form-input v-model="pin" placeholder="ПИН" size="sm" style="background-color: #c6ced4"/>
            <b-input-group-append>
              <b-btn size="sm" :disabled="!pin" @click="pin = ''" ><b-icon-backspace></b-icon-backspace></b-btn>
            </b-input-group-append>
          </b-input-group>
          <b-input-group class="my-1">
            <b-form-input v-model="phone" placeholder="Номер телефона" size="sm" style="background-color: #c6ced4"/>
            <b-input-group-append>
              <b-btn size="sm" :disabled="!phone" @click="phone = ''" ><b-icon-backspace></b-icon-backspace></b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col md="3">
          <b-input-group class="my-1">
            <b-form-input v-model="surname" placeholder="Фамилия" size="sm" style="background-color: #c6ced4"/>
            <b-input-group-append>
              <b-btn size="sm" :disabled="!surname" @click="surname = ''" ><b-icon-backspace></b-icon-backspace></b-btn>
            </b-input-group-append>
          </b-input-group>
          <b-input-group class="my-1">
            <b-form-input v-model="name" placeholder="Имя" size="sm" style="background-color: #c6ced4"/>
            <b-input-group-append>
              <b-btn size="sm" :disabled="!name" @click="name = ''" ><b-icon-backspace></b-icon-backspace></b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-table striped hover small show-empty
                table-variant="dark"
                style="color: #ccc"
                stacked="md"
                :items="items"
                :fields="fields"
                @row-clicked="expandAdditionalInfo"
      >
      </b-table>
      <template slot="row-details" slot-scope="row">
        <b-card name="bcard" bg-variant="dark" text-variant="secondary">
          <p>row.item</p>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>
        </b-card>
      </template>
      <infinite-loading @infinite="querytabledata" ref="infiniteLoading">
        <div slot="no-more">Больше нет анкетных танных</div>
        <div slot="no-results"></div>
      </infinite-loading>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios';
import config from '../_services/config.json';

export default {
  name: 'suppliers',
  data: () =>  ({
    isBusy: false,
    items: [],
    fields: [
      { label: 'Номер телефона', key: "info.phone", sortable: true, 'class': 'text-left' },
      { label: 'ПИН', key: "info.pin", sortable: true, 'class': 'text-left' },
      { label: 'Фамилия', key: "info.surname", sortable: true, 'class': 'text-left' },
      { label: 'Имя', key: "info.name", 'class': 'text-left' },
      { label: 'Отчество', key: "info.patronomic", 'class': 'text-left' },
      { label: 'Адрес', key: "info.customerAddress", 'class': 'text-left' }
    ],
    perPage: 50,
    page:0,
    index: 0,
    totalsum: 0,
    pin: null,
    phone: null,
    surname: null,
    name: null,
  }),
  computed: {
    isOwner () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.partnertype==='Владелец'
      else return false
    },
    isBank () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.partnertype==='Банк'
      else return false
    },
    sortOptions () {
    // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => { return { text: f.label, value: f.key } })
    },
    filterItems () {
      const filteredItems = this.items.filter(item => ((this.dealer==null ? this.dealer: item.dealerid)==this.dealer));
      this.onFiltered (filteredItems);
      return filteredItems
    },
  },

  methods: {
    updatedata () {
        this.page=0;
        this.items=[];
        this.$refs.infiniteLoading.stateChanger.reset();
    },
    querytabledata ($state) {
      axios
      .post(`${config.apiUrl}/query/getdata`,{ DB: 'prodDB', partnerId: this.$store.state.authentication.user.partnerid,
                                              type: ['Profiles'], partnertype: this.$store.state.authentication.user.partnertype,
                                              pin: this.pin, phone: this.phone, surname: this.surname, name: this.name,
                                              rows: this.perPage, page: this.page,
                                              token: this.$store.state.authentication.user.token })
      .then(response => {
        this.items = this.items.concat(response.data);
        this.page +=this.perPage;
        $state.loaded();
        if (response.data.length<this.perPage) {
          $state.complete();
        }
      })
      .catch(e => {alert (e); return [e]})
    },
    expandAdditionalInfo(row) {
      row.detailsShowing ? 'Hide' : 'Show';
    }
  },
  created() {
    this.updatedata ()
  }
}
//<b-table striped hover :items="items" :fields="fields"></b-table>
</script>
<style scoped>
.tablebody{
  background-color: #21252b;
  font-size: 14px;
}
.info {
  display: flex;
  padding: 10px;
  justify-content: center;

}
.paycount {
  display: block;
  width: auto;
  margin: 10px;
  padding: 5px 10px;
  text-align: center;
  background-color: #6c757d;
  color: orange;
  border-radius: 3px;
}
</style>
